import moment from "moment";

export default {
  data: function () {
    return {
      GROUPS: [
        { animal: "ປານ້ອຍ", numbers: ["01", "41", "81"] },
        { animal: "ຫອຍ", numbers: ["02", "42", "82"] },
        { animal: "ຫ່ານ", numbers: ["03", "43", "83"] },
        { animal: "ນົກຍຸງ", numbers: ["04", "44", "84"] },
        { animal: "ສິງ", numbers: ["05", "45", "85"] },
        { animal: "ເສືອ", numbers: ["06", "46", "86"] },
        { animal: "ໝູ", numbers: ["07", "47", "87"] },
        { animal: "ກະຕ່າຍ", numbers: ["08", "48", "88"] },
        { animal: "ຄວາຍ", numbers: ["09", "49", "89"] },
        { animal: "ນາກນໍ້າ", numbers: ["10", "50", "90"] },
        { animal: "ໝາ", numbers: ["11", "51", "91"] },
        { animal: "ມ້າ", numbers: ["12", "52", "92"] },
        { animal: "ຊ້າງ", numbers: ["13", "53", "93"] },
        { animal: "ແມວບ້ານ", numbers: ["14", "54", "94"] },
        { animal: "ໜູ", numbers: ["15", "55", "95"] },
        { animal: "ເຜິ້ງ", numbers: ["16", "56", "96"] },
        { animal: "ນົກຍາງ", numbers: ["17", "57", "97"] },
        { animal: "ແມວປ່າ", numbers: ["18", "58", "98"] },
        { animal: "ແມງກະເບື້ອ", numbers: ["19", "59", "99"] },
        { animal: "ຂີ້ເຂັບ", numbers: ["00", "20", "60"] },
        { animal: "ນົກແອ່ນ", numbers: ["21", "61"] },
        { animal: "ນົກກາງແກ", numbers: ["22", "62"] },
        { animal: "ລີງ", numbers: ["23", "63"] },
        { animal: "ກົບ", numbers: ["24", "64"] },
        { animal: "ແຫຼວ", numbers: ["25", "65"] },
        { animal: "ນາກບິນ", numbers: ["26", "66"] },
        { animal: "ເຕົ່າ", numbers: ["27", "67"] },
        { animal: "ໄກ່", numbers: ["28", "68"] },
        { animal: "ອ່ຽນ", numbers: ["29", "69"] },
        { animal: "ປາໃຫຍ່", numbers: ["30", "70"] },
        { animal: "ກຸ້ງ", numbers: ["31", "71"] },
        { animal: "ງູ", numbers: ["32", "72"] },
        { animal: "ແມງມຸມ", numbers: ["33", "73"] },
        { animal: "ກວາງ", numbers: ["34", "74"] },
        { animal: "ແບ້", numbers: ["35", "75"] },
        { animal: "ເຫງັນ", numbers: ["36", "76"] },
        { animal: "ຫຼິ່ນ", numbers: ["37", "77"] },
        { animal: "ເໝັ້ນ", numbers: ["38", "78"] },
        { animal: "ກະປູ", numbers: ["39", "79"] },
        { animal: "ນົກອິນຊີ", numbers: ["40", "80"] },
      ],
      GATEWAYS: [
        {
          code: "FINLINK",
          name: "FINLINK",
          marketid: 23,
        },
        {
          code: "BCEL",
          name: "​ທະນາຄານ ການຄ້າຕ່າງປະເທດລາວ",
          marketid: 8,
        },
        {
          code: "LDB",
          name: "​ທະນາຄານ ພັດທະນາລາວ",
          marketid: 10,
        },
        {
          code: "APB",
          name: "ທະນາຄານ ສົ່ງເສີມກະສິກຳ",
          marketid: 22,
        },
        {
          code: "IB",
          name: "ທະນາຄານ ອິນໂດຈີນ",
          marketid: 21,
        },
        {
          code: "JDB",
          name: "​ທະນາຄານ ຮ່ວມພັດທະນາ",
          marketid: 11,
        },
        {
          code: "LVB",
          name: "​ທະນາຄານ ລາວ-ຫວຽດ",
          marketid: 19,
        },
        { code: "MMONEY", name: "M-Money", marketid: 16 },
        { code: "UMONEY", name: "U-Money", marketid: 17 },
      ],
    };
  },
  filters: {
    formatnumber(x) {
      if (!x) return 0;
      if (x) {
        return (x % 1 != 0 ? x.toFixed(2) : x)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return x;
    },
    datetime(x) {
      if (x) {
        return moment(x).format("DD/MM/YYYY HH:mm:ss");
      }
      return x;
    },
    date(x) {
      if (x) {
        return moment(x).format("DD/MM/YYYY");
      }
      return x;
    },
    time(x) {
      if (x) {
        return moment(x).format("HH:mm:ss");
      }
      return x;
    },
  },
  methods: {
    getAnimalImage(num) {
      return `animals/${this.getAnimalName(num)}.png`;
    },
    getAnimalName(num) {
      let num2 = num;
      if (num2.length >= 2) {
        num2 = num2.slice(-2);
      } else {
        num2 = "0" + num2;
      }

      const match = this._.find(this.GROUPS, (g) => {
        return g.numbers.includes(num2);
      });
      return match.animal;
    },
    formatnumber(x) {
      if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
      return x;
    },
    datetime(x) {
      if (x) {
        return moment(x).format("DD/MM/YYYY HH:mm:ss");
      }
      return x;
    },
    date(x) {
      if (x) {
        try {
          return moment(x).format("DD/MM/YYYY");
        } catch (error) {
          console.error(error);
        }
      }
      return x;
    },
  },
  computed: {},
};
