import Vue from "vue";
import vuex from "vuex";
import pathify from "./pathify";
import { make } from "vuex-pathify";
import createPersistedState from "vuex-persistedstate";

Vue.use(vuex);
const state = {
  userid: null,
  user: null,
  lottery: null,
  lotterytotalamount: null,
  round: null,
  israndombill: null,
  randombilldigit: null,
  randombillamount: null,
  lotteryticket: null,
  paymentway: null,
  selectedtab: null,
  mcid: null,
  device: null,
  webversion: null,
};
const mutations = make.mutations(state);
const store = new vuex.Store({
  state,
  mutations,
  plugins: [
    pathify.plugin,
    createPersistedState({
      key: "laolot",
      storage: window.localStorage,
    }),
  ],
});

global.store = store;
export default store;
