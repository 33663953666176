import VueRouter from "vue-router";
import store from "./store";
const routes = [
  { path: "/", name: "/", component: () => import("@/views/HomePage.vue") },
  {
    path: "/home",
    name: "/home",
    component: () => import("@/views/HomePage.vue"),
  },
  {
    path: "/message",
    name: "/message",
    component: () => import("@/views/MessagePage.vue"),
  },
  {
    path: "/profile",
    name: "/profile",
    component: () => import("@/views/ProfilePage.vue"),
  },
  {
    path: "/editprofile",
    name: "/editprofile",
    component: () => import("@/views/EditProfilePage.vue"),
  },
  {
    path: "/settings",
    name: "/settings",
    component: () => import("@/views/SettingsPage.vue"),
  },
  {
    path: "/terms",
    name: "/terms",
    component: () => import("@/views/TermsPage.vue"),
  },
  {
    path: "/live",
    name: "/live",
    component: () => import("@/views/LivePage.vue"),
  },
  {
    path: "/transfer",
    name: "/transfer",
    component: () => import("@/views/TransferPage.vue"),
  },
  {
    path: "/withdraw",
    name: "/withdraw",
    component: () => import("@/views/WithdrawPage.vue"),
  },
  {
    path: "/topup",
    name: "/topup",
    component: () => import("@/views/TopupPage.vue"),
  },
  {
    path: "/resulthistory",
    name: "/resulthistory",
    component: () => import("@/views/ResultHistoryPage.vue"),
  },
  {
    path: "/buyhistory",
    name: "/buyhistory",
    component: () => import("@/views/BuyHistoryPage.vue"),
  },
  {
    path: "/goodnumber",
    name: "/goodnumber",
    component: () => import("@/views/GoodNumberPage.vue"),
  },
  {
    path: "/otp",
    name: "/otp",
    component: () => import("@/views/OtpPage.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginPage.vue"),
  },
  { path: "/buy", name: "buy", component: () => import("@/views/BuyPage.vue") },
  {
    path: "/test",
    name: "test",
    component: () => import("@/views/TestPage.vue"),
  },
  {
    path: "/registerPhone",
    name: "registerPhone",
    component: () => import("@/views/RegisterPhone.vue"),
  },
  {
    path: "/registerForm",
    name: "registerForm",
    component: () => import("@/views/RegisterForm.vue"),
  },
  {
    path: "/forgotPassword",
    name: "forgotPassword",
    component: () => import("@/views/ForgetPassword.vue"),
  },
  {
    path: "/banks",
    name: "banks",
    component: () => import("@/views/BanksPage.vue"),
  },
  {
    path: "/statement",
    name: "statement",
    component: () => import("@/views/StatementPage.vue"),
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  if (
    ![
      "/terms",
      "/otp",
      "/news",
      "/shopping",
      "/promotions",
      "/login",
      "/registerPhone",
      "/registerForm",
      "/forgotPassword",
      "/test",
    ].includes(to.path)
  ) {
    if (!store.get("user")) {
      router.replace({ path: "/login" });
    }
  }
  next();
});

export default router;
