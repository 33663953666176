<template>
  <div class="flex py-2 bgblue">
    <div class="w-20 pl-2" @click="goback">
      <img v-if="isShowBack" src="arrowleft.png" alt="" class="w-4 mt-2" />
    </div>
    <div class="flex-auto text-xl font-bold pt-1 text-white text-center">
      {{ title }}
    </div>
    <div class="w-20 pr-2 flex justify-end">
      <slot name="context"></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavBar",
  props: {
    title: String(""),
    isShowBack: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    goback() {
      if (this.isShowBack) {
        this.$router.back();
      }
    },
  },
};
</script>
