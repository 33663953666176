<template>
  <div>
    <div class="flex bgblue">
      <div class="flex-1" @click="() => select('home')">
        <div class="flex justify-center">
          <span
            :class="
              !selectedtab || selectedtab === 'home'
                ? 'bg-white rounded-full p-4 -mt-8 border-4 borderblue'
                : 'p-2'
            "
          >
            <img
              :src="
                !selectedtab || selectedtab === 'home'
                  ? 'home-blue.png'
                  : 'home-white.png'
              "
              alt=""
              class=""
              :class="!selectedtab || selectedtab === 'home' ? 'w-10' : 'w-8'"
            />
          </span>
        </div>
        <p class="text-center text-white">ໜ້າຫຼັກ</p>
      </div>
      <div class="flex-1" @click="() => select('message')">
        <div class="flex justify-center">
          <span
            :class="
              selectedtab === 'message'
                ? 'bg-white rounded-full p-4 -mt-8 border-4 borderblue'
                : 'p-2'
            "
          >
            <img
              :src="
                selectedtab === 'message' ? 'email-blue.png' : 'email-white.png'
              "
              alt=""
              class=""
              :class="selectedtab === 'message' ? 'w-10' : 'w-8'"
            />
          </span>
        </div>
        <p class="text-center text-white">ກ່ອງຂໍ້ຄວາມ</p>
      </div>
      <div class="flex-1" @click="() => select('profile')">
        <div class="flex justify-center">
          <span
            :class="
              selectedtab === 'profile'
                ? 'bg-white rounded-full p-4 -mt-8 border-4 borderblue'
                : 'p-2'
            "
          >
            <img
              :src="
                selectedtab === 'profile' ? 'user-blue.png' : 'user-white.png'
              "
              alt=""
              class=""
              :class="selectedtab === 'profile' ? 'w-10' : 'w-8'"
            />
          </span>
        </div>
        <p class="text-center text-white">ຜູ້ໃຊ້</p>
      </div>
      <div class="flex-1" @click="() => select('settings')">
        <div class="flex justify-center">
          <span
            :class="
              selectedtab === 'settings'
                ? 'bg-white rounded-full p-4 -mt-8 border-4 borderblue'
                : 'p-2'
            "
          >
            <img
              :src="
                selectedtab === 'settings'
                  ? 'settings-blue.png'
                  : 'settings-white.png'
              "
              alt=""
              class=""
              :class="selectedtab === 'settings' ? 'w-10' : 'w-8'"
            />
          </span>
        </div>
        <p class="text-center text-white">ຕັ້ງຄ່າ</p>
      </div>
    </div>
  </div>
</template>
<script>
import { get } from "vuex-pathify";
export default {
  name: "BottomNav",
  computed: {
    selectedtab: get("selectedtab"),
  },
  mounted() {
    const path = this.$route.path;
    if (path !== "/") {
      this.select(path.replace("/", ""));
    }
  },
  methods: {
    select(t) {
      const path = this.$route.path;
      if (path.indexOf(t) != 1) {
        this.$store.set("selectedtab", t);
        this.$router.push({ path: "/" + t });
      }
    },
  },
};
</script>
