var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"h-full",attrs:{"id":"app"}},[(
      !['/', '/home', '/message', '/profile', '/settings'].includes(
        _vm.$route.path
      )
    )?_c('div',{staticClass:"h-full bg overflow-y-hidden"},[_c('router-view')],1):_vm._e(),(
      ['/', '/home', '/message', '/profile', '/settings'].includes(
        _vm.$route.path
      )
    )?_c('div',{staticClass:"h-full"},[_c('div',{staticClass:"h-full flex flex-col overflow-y-hidden bg"},[(!['/', '/home'].includes(_vm.$route.path))?_c('navbar',{attrs:{"isShowBack":false,"title":_vm.gettitle()}}):_vm._e(),_c('div',{staticClass:"flex-1 flex flex-col pt-4 overflow-y-scroll"},[_c('router-view')],1),_c('bottom-nav')],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }