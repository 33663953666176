<template>
  <div id="app" class="h-full">
    <div
      v-if="
        !['/', '/home', '/message', '/profile', '/settings'].includes(
          $route.path
        )
      "
      class="h-full bg overflow-y-hidden"
    >
      <router-view />
    </div>

    <div
      v-if="
        ['/', '/home', '/message', '/profile', '/settings'].includes(
          $route.path
        )
      "
      class="h-full"
    >
      <div class="h-full flex flex-col overflow-y-hidden bg">
        <navbar
          v-if="!['/', '/home'].includes($route.path)"
          :isShowBack="false"
          :title="gettitle()"
        />
        <div class="flex-1 flex flex-col pt-4 overflow-y-scroll">
          <router-view />
        </div>
        <bottom-nav />
      </div>
    </div>
  </div>
</template>

<script>
import BottomNav from "./components/BottomNav.vue";
import Navbar from "./components/Navbar.vue";
export default {
  name: "App",
  components: { BottomNav, Navbar },
  data() {
    return {
      route: null,
    };
  },
  mounted() {},
  methods: {
    gettitle() {
      switch (this.$route.path) {
        case "/message":
          return "ຂໍ້ຄວາມ";
        case "/profile":
          return "ຜູ້ໃຊ້";
        case "/settings":
          return "ຕັ້ງຄ່າ";
      }
    },
  },
};
</script>

<style>
@font-face {
  font-family: "NotoLao";
  src: url("./assets/fonts/NotoSansLao-VariableFont_wdth,wght.ttf")
    format("truetype");
}
#app {
  font-family: NotoLao;
}
.navbar,
.bgblue {
  background-color: #027dc5;
}
.borderblue {
  border-color: #027dc5;
}
.bg {
  background-color: #1c60e7;
  background-image: linear-gradient(to bottom, #1c60e7, #a3d0ff);
}
.swal2-popup,
.swal2-title,
.swal2-confirm,
.swal2-cancel {
  font-family: NotoLao;
}
</style>
