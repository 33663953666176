var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"flex bgblue"},[_c('div',{staticClass:"flex-1",on:{"click":() => _vm.select('home')}},[_c('div',{staticClass:"flex justify-center"},[_c('span',{class:!_vm.selectedtab || _vm.selectedtab === 'home'
              ? 'bg-white rounded-full p-4 -mt-8 border-4 borderblue'
              : 'p-2'},[_c('img',{class:!_vm.selectedtab || _vm.selectedtab === 'home' ? 'w-10' : 'w-8',attrs:{"src":!_vm.selectedtab || _vm.selectedtab === 'home'
                ? 'home-blue.png'
                : 'home-white.png',"alt":""}})])]),_c('p',{staticClass:"text-center text-white"},[_vm._v("ໜ້າຫຼັກ")])]),_c('div',{staticClass:"flex-1",on:{"click":() => _vm.select('message')}},[_c('div',{staticClass:"flex justify-center"},[_c('span',{class:_vm.selectedtab === 'message'
              ? 'bg-white rounded-full p-4 -mt-8 border-4 borderblue'
              : 'p-2'},[_c('img',{class:_vm.selectedtab === 'message' ? 'w-10' : 'w-8',attrs:{"src":_vm.selectedtab === 'message' ? 'email-blue.png' : 'email-white.png',"alt":""}})])]),_c('p',{staticClass:"text-center text-white"},[_vm._v("ກ່ອງຂໍ້ຄວາມ")])]),_c('div',{staticClass:"flex-1",on:{"click":() => _vm.select('profile')}},[_c('div',{staticClass:"flex justify-center"},[_c('span',{class:_vm.selectedtab === 'profile'
              ? 'bg-white rounded-full p-4 -mt-8 border-4 borderblue'
              : 'p-2'},[_c('img',{class:_vm.selectedtab === 'profile' ? 'w-10' : 'w-8',attrs:{"src":_vm.selectedtab === 'profile' ? 'user-blue.png' : 'user-white.png',"alt":""}})])]),_c('p',{staticClass:"text-center text-white"},[_vm._v("ຜູ້ໃຊ້")])]),_c('div',{staticClass:"flex-1",on:{"click":() => _vm.select('settings')}},[_c('div',{staticClass:"flex justify-center"},[_c('span',{class:_vm.selectedtab === 'settings'
              ? 'bg-white rounded-full p-4 -mt-8 border-4 borderblue'
              : 'p-2'},[_c('img',{class:_vm.selectedtab === 'settings' ? 'w-10' : 'w-8',attrs:{"src":_vm.selectedtab === 'settings'
                ? 'settings-blue.png'
                : 'settings-white.png',"alt":""}})])]),_c('p',{staticClass:"text-center text-white"},[_vm._v("ຕັ້ງຄ່າ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }